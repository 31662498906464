p {
    margin: 0px 0px 25px;
    font-weight: bold;
    font-size: 14px;
}

.ihbox-section-two {
    margin-top: 12px;
    position: relative;
    z-index: 1;
}

element.style {
    border: 1px solid #E0E0E0;
    background: #F0F0F0;
    margin-bottom: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.icon{
    width: 20px;
    color: var(--pbmit-global-color);
}

.email{
    font-size: 15px;
    font-weight: 600;
    margin: 0 10px;
}

p {
    margin: 0px 0px 25px;
    font-weight: 500;
    font-size: 14px;
}

/* .pbmit-icon-skincolor{
    padding: 8px;
} */

.ihbox-section-two .col-md-4:nth-child(2) .pbmit-ihbox-style-5{
    padding: 37px;
}

.ihbox-section-two .col-md-4:last-child .pbmit-ihbox-style-5 {
    padding: 52px;
}



/* ********************LEGAL*********************** */

.check{
    width: 12px;
}