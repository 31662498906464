p{
    margin: 0px 0px 25px;
    font-weight: bold;
    font-size: 25px;
}

.header-style-7 .site-header-menu {
    height: 80px;
}

.justify-content-between {
    /* justify-content: none !important; */
    justify-content: flex-start!important;
}

.header-style-7 .site-branding {
    padding-left: 60px;
    padding-right: 70px;
    border-right: 0;
}

.slick-dots {
    position: absolute;
    bottom: 7px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-next {
    right: -25px;
}

.section-lg {
    margin-top: -10px;
    padding-top: 96px;
    padding-bottom: 140px;
}

 .ri-phone-fill{
    /* border: 0 solid blue; */
    /* margin: 0 2px; */
    border: 1px solid #17308e !important;
 }

 .space{
    margin: 0 60px;
 }

 /* Navbar.css */

  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-brand {
    color: #fff;
    font-size: 1.5rem;
  }
  
  .mobile-toggle {
    display: none; /* Hide by default for larger screens */
    cursor: pointer;
  }
  
  .mobile-toggle span {
    background-color: #fff;
    height: 3px;
    width: 25px;
    display: block;
    margin: 4px 0;
  }
  
  .navbar-links {
    display: flex;
    z-index: 1;
  }

  /* .navbar-links.mobile-open{
    z-index: 1;
  } */
  
  .navbar-links a {
    color: #fff;
    text-decoration: none;
    padding: 0 15px;
    font-size: 1rem;
  }
 
  
  /* Media query for mobile toggle */
  @media (max-width: 768px) {
    .mobile-toggle {
      display: block;
    }
  
    .navbar-links {
      flex-direction: column;
      position: absolute;
      top: 45px;
      left: 45;
      width: 100%;
      display: none;
    }
  
    .navbar-links.mobile-open {
        display: flex;
        background: rgb(233, 232, 232);
        width: 389px;
        height: 256px;
        left: -358px;
        z-index: 2;
        line-height: 35px;
    }
    .navbar {
        background-color: #163483f5;
        margin-left: 133px;
        --bs-navbar-padding-x: 0;
        --bs-navbar-padding-y: 0rem !important;
      }
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* **************NAVBAR 1*************** */

  .dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

/* ********************* */


.head{
  font-size: 42px;
  padding-left: 15px;
  font-family: var(--pbmit-heading-typography-font-family);
  font-weight: var(--pbmit-heading-font-variant);
  color: var(--pbmit-heading-color);
}
.mr-auto{
  padding-left: 10rem;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  margin: 4px 31px;
  color: var(--pbmit-heading-color);
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .head {
    font-size: 35px;
    margin-left: -138px;
    margin-top: 26px;
    /* margin: -5px -114px; */
    height: 85px;
    /* padding-left: 15px; */
    font-family: var(--pbmit-heading-typography-font-family);
    font-weight: var(--pbmit-heading-font-variant);
    color: var(--pbmit-heading-color);
}  
  .dropdown-menu[data-bs-propper]{
    left: 18rem;
  }
  .navbar-nav .dropdown-menu {
    border: none;
    margin-left: -18.5rem;
    background-color: rgb(252, 252, 252);
}
.nav-link {
  display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    margin: 4px -268px;
    /* background: red; */
    color: var(--pbmit-heading-color);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    width: 100%;
    overflow-x: hidden;
}
}
